<template>
  <div class="registration_view">
    <a-form>
      <div class="header-switching-buttons-container">
        <router-link to="/login">
          <a-button type="link" ghost class="header-switching__button">
            Вход
          </a-button>
        </router-link>
        <a-button type="link" class="header__switching__button__active">
          Регистрация
        </a-button>
      </div>

      <a-button-group class="registration__master__switching__buttons">
        <router-link to="/registration_customer">
          <Button
            class="registration__master__button__switch"
            :style="'border-top-left-radius: 3px; border-top-right-radius: 0px 0px;border-bottom-right-radius: 0px 0px;border-bottom-left-radius:  3px 4px;'"
            >Заказчик</Button
          >
        </router-link>

        <div class="registration__master__button__switch__active">
          <Button
            buttonClass="white-antd-button-height38"
            :style="'border-radius: 3px; border-top-left-radius: 0px; border-top-right-radius: 3px ;border-bottom-right-radius: 3px;border-bottom-left-radius:  0px;'"
            >Мастер</Button
          >
        </div>
      </a-button-group>
      <a-form-item name="name" :validateStatus="statusName" :help="helpName">
        <a-input
          :value="form.name"
          v-on:input="updateFormName($event)"
          size="large"
          placeholder="Имя"
        />
      </a-form-item>

      <a-form-item :validateStatus="statusSurname" :help="helpSurname">
        <a-input
          :value="form.surname"
          v-on:input="updateFormSurname($event)"
          size="large"
          placeholder="Фамилия"
        />
      </a-form-item>
      <a-form-item :validateStatus="statusMiddlename" :help="helpMiddlename">
        <a-input
          :value="form.middlename"
          v-on:input="updateFormMiddlename($event)"
          size="large"
          placeholder="Отчество"
        />
      </a-form-item>

      <a-form-item>
        <UploadFile
          name="Загрузить Фото"
          :files="form.files"
          @unloadFile="unloadFile"
        ></UploadFile>
      </a-form-item>

      <a-form-item :validateStatus="statusWorkProfile" :help="helpWorkProfile">
        <a-select
          placeholder="Профиль работ"
          class="a-form-item-selec"
          size="large"
          @change="updateFormWorkProfile"
          showArrow
        >
          <a-select-option v-for="i in getWorkTagsValue()" :key="i.id">
            {{ i.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item
        class="a-form-item-selec"
        :validateStatus="statusExepirience"
        :help="helpExepirience"
      >
        <a-select
          placeholder="Стаж работы"
          class="a-form-item-selec"
          size="large"
          @change="updateFormExepirience"
        >
          <a-select-option value="менее года">менее года</a-select-option>
          <a-select-option value="от года до трех">
            от года до трех
          </a-select-option>
          <a-select-option value="от трех до пяти">
            от трех до пяти
          </a-select-option>
          <a-select-option value="более пяти лет">
            более пяти лет
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item :validateStatus="statusEmail" :help="helpEmail">
        <a-input
          :value="form.email"
          v-on:input="updateFormEmail($event)"
          size="large"
          placeholder="Почта"
        />
      </a-form-item>

      <a-form-item :validateStatus="statusPassword" :help="helpPassword">
        <a-input-password
          :value="form.password"
          v-on:input="updateFormPassword($event)"
          size="large"
          placeholder="Пароль"
        />
      </a-form-item>
      <a-form-item
        :validateStatus="statusPasswordRepeat"
        :help="helpPasswordRepeat"
      >
        <a-input-password
          :value="form.password_confirmation"
          v-on:input="updateFormPasswordConfirmation($event)"
          size="large"
          placeholder="Повторите Пароль"
        />
      </a-form-item>

      <a-form-item>
        <Button @click="submitForm" buttonClass="red-antd-button">
          Зарегистрироваться
        </Button>
      </a-form-item>

      <a-form-item :validateStatus="statusAsign" :help="helpAsign">
        <div class="asign-user-agree">
          <Checkbox
            @change="updatePrivacy"
            className="checkbox-style"
          ></Checkbox>
          <div class="asign-user-agree-text">
            Я согласен с условиями
            <a class="link">Пользовательского соглашения</a>
            и
            <a class="link">Политикой обработки персональных данных</a>
          </div>
        </div>
      </a-form-item>
      <div class="button-entry-account">
        <router-link to="/main">
          <div>Войти в аккаунт</div>
        </router-link>
      </div>
    </a-form>
  </div>
</template>

<script>
import Checkbox from 'ant-design-vue/lib/checkbox'
import Button from '@/components/common/Button.vue'
import UploadFile from '@/components/common/UploadFile.vue'
import { login } from '@/helpers/authHelper'

import { deleteFile } from '@/helpers/dataHelpers'
// import * as EmailValidator from "email-validator";
import { validateEmail } from '@/helpers/validation'

export default {
  components: {
    Checkbox,
    Button,
    UploadFile
  },
  mounted () {
    this.$store.dispatch('setTags', this.$store.getters.getTags)
  },

  data () {
    return {
      statusName: 'success',
      helpName: '',
      statusSurname: 'success',
      helpSurname: '',
      statusMiddlename: 'success',
      helpMiddlename: '',
      statusEmail: 'success',
      helpEmail: '',
      statusPassword: 'success',
      helpPassword: '',
      statusPasswordRepeat: 'success',
      helpPasswordRepeat: '',
      statusAsign: 'success',
      helpAsign: '',
      statusExepirience: 'success',
      helpExepirience: '',
      statusWorkProfile: 'success',
      helpWorkProfile: '',
      headers: {
        authorization: 'authorization-text'
      },
      form: {
        name: '',
        surname: '',
        middlename: '',
        subtagId: [],
        workExperience: '',
        email: '',
        password: '',
        password_confirmation: '',
        privacy: false,
        image: ''
      },
      userPersonalData: {}
    }
  },
  methods: {
    unloadFile (file) {
      if (this.form.image) {
        deleteFile(this.form.image)
      }
      this.form.image = file
    },
    getWorkTagsValue () {
      let arr = []
      this.$store.getters.getTags.map((object) => {
        let subtags = object.subtags
        for (var subtag of subtags) {
          arr.push(subtag)
        }
      })

      return arr
    },
    submitForm () {
      if (this.form.name === '') {
        this.statusName = 'error'
        this.helpName = 'Пустое поле'
      }
      if (this.form.surname === '') {
        this.statusSurname = 'error'
        this.helpSurname = 'Пустое поле'
      }
      if (this.form.middlename === '') {
        this.statusMiddlename = 'error'
        this.helpMiddlename = 'Пустое поле'
      }
      if (this.form.workExperience === '') {
        this.statusExepirience = 'error'
        this.helpExepirience = 'Пустое поле'
      }

      if (this.form.subtagId === '') {
        this.statusWorkProfile = 'error'
        this.helpWorkProfile = 'Пустое поле'
      }
      if (this.form.email === '') {
        this.statusEmail = 'error'
        this.helpEmail = 'Пустое поле'
      }
      if (!validateEmail(this.form.email)) {
        this.statusEmail = 'error'
        this.helpEmail = 'Некорректная почта'
      }
      if (this.form.password.length < 5) {
        this.statusPassword = 'error'
        this.helpPassword = 'Пароль не может быть меньше 5 символов'
      }
      if (this.form.password === '') {
        this.statusPassword = 'error'
        this.helpPassword = 'Пустое поле'
      }

      if (this.form.password !== this.form.password_confirmation) {
        this.statusPasswordRepeat = 'error'
        this.helpPasswordRepeat = 'Пароли не совпадают'
      }
      if (this.form.privacy === false) {
        this.statusAsign = 'error'
        this.helpAsign = 'Подтвердите согласие'
      }

      if (
        this.statusName === 'success' &&
        this.statusSurname === 'success' &&
        this.statusMiddlename === 'success' &&
        this.statusEmail === 'success' &&
        this.statusPassword === 'success' &&
        this.statusExepirience === 'success' &&
        this.statusWorkProfile === 'success' &&
        this.statusExepirience === 'success' &&
        this.statusPasswordRepeat === 'success' &&
        this.statusAsign === 'success'
      ) {
        this.sendForm()
      }
    },
    updateFormName (e) {
      this.form.name = e.target.value
      this.statusName = 'success'
      this.helpName = ''
    },
    updateFormSurname (e) {
      this.form.surname = e.target.value
      this.statusSurname = 'success'
      this.helpSurname = ''
    },
    updateFormMiddlename (e) {
      this.form.middlename = e.target.value
      this.statusMiddlename = 'success'
      this.helpMiddlename = ''
    },
    updateFormWorkProfile (value) {
      this.form.subtagId = value;
      this.statusWorkProfile = 'success'
      this.helpWorkProfile = ''
    },
    updateFormExepirience (value) {
      this.form.workExperience = value
      this.statusExepirience = 'success'
      this.helpExepirience = ''
    },
    updateFormEmail (e) {
      this.form.email = e.target.value
      this.statusEmail = 'success'
      this.helpEmail = ''
    },
    updateFormPassword (e) {
      this.form.password = e.target.value
      this.statusPassword = 'success'
      this.helpPassword = ''
    },
    updateFormPasswordConfirmation (e) {
      this.form.password_confirmation = e.target.value
      this.statusPasswordRepeat = 'success'
      this.helpPasswordRepeat = ''
    },
    updatePrivacy (e) {
      this.form.privacy = e.target.checked
      this.statusAsign = 'success'
      this.helpAsign = ''
    },
    sendForm () {
      this.$http.post(process.env.VUE_APP_SERVER_URL + '/users/user', this.form)
        .then((responce) => {
          const data = responce.data.data
          if ( data.token) {
            login({
              image: data.image,
              name: data.name,
              uuid: data.uuid,
              token: data.token,
              userType: data.roles.name
            })
            this.$router.push('/main')
          } else {
            this.$notification.open({
              message: 'Ошибка регистрации',
              description: responce.data.message
            })
          }
        })
          .catch((error) => {
            let description = ''
            if (error.response && error.response.message) {
              description = error.response.message
            } else if (error.response && error.response.data && error.response.data.message) {
              description = error.response.data.message
            }
            Object.keys(error.response.data.data || {}).forEach((key) => {
                  console.log(error.response.data.data)
                  description += error.response.data.data[key][0]
                }
            )
            this.$notification.open({
              message: 'Сетевая ошибка входа в систему',
              description: description
            })
          })
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/themes/layout.css";

.registration__master {
  &__button__switch {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    border-radius: 3px 0px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border: 1px solid #d9d9d9;
    border-right: 0px;
    padding: 5px 16px;

    &:hover {
      color: #1890ff;
      border: 1px solid #1890ff;
    }
    &__active {
      border-radius: 3px;
      border: 1px solid #b12021;
      border-top-left-radius: 0px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 0px;
    }
  }
  &__switching__buttons {
    margin-bottom: 19px;
  }
}

.header__switching__button {
  color: black;
  &__active {
    border-bottom: 2px solid #b12021;
    color: #b12021;
    border-radius: 0px;
  }
}

.header-switching-buttons-container {
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 29px;
}
.header-switching-button {
  color: black;
}
.active {
  /* border-bottom: 2px solid #b12021; */
  color: #b12021;
}

.header-switching-buttons-master-customer {
  margin-bottom: 19px;
}

.send-file-button-container {
  /* border: 1px solid #b12021; */
  color: #b12021;
  height: 40px;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.send-file-button-content {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.send-file-button-content-text {
  margin-left: 20px;
}

.registration-button {
  color: white;
  height: 40px;
  background-color: #b12021;
  width: 100%;
}

.button-entry-account {
  color: #b12021;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.a-form-item-input {
  height: 40px;
}

.a-form-item-select {
  height: 40px;
}

.asign-user-agree {
  display: flex;
  flex-direction: row;
}

.asign-user-agree-text {
  margin-left: 8px;
}

.link {
  color: black;
  text-decoration: underline;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #b12021;
  border-color: #b12021;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  background-color: #efe1e1;
  color: #b12021;
}
.ant-btn.ant-btn-link.ant-btn-background-ghost {
  color: black;
}
</style>
